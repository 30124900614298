/*
Home Page
*/
import './home.css';
import React, { useState } from 'react';
import SignUpForm from "../../Components/NavBar/SignUpForm";

// Components
import Footer from '../../Components/Footer'
import DropzoneComponent from './DropzoneComponent';
import IPLogger from '../../Components/Logger/IPLogger';

const Home = ({ loggedin, email }) => {
    let securityLog = <></>;
    if (process.env.NODE_ENV === 'production') {
        securityLog = <IPLogger email={email} />;
    } else {
        securityLog = <></>;
    }

    if (loggedin) {
        return (<div className="home-container">
            {securityLog}
            <DropzoneComponent email={email} />
            <Footer />
        </div>);
    } else {
        return (<div className="home-container">
            {/* Hero Section */}
            <section className="hero">
                <h1>Welcome to DropFiling</h1>
                <p>Your ultimate solution for seamless file management and document organization.</p>
                <button className="cta-button">Get Started</button>
            </section>

            {/* About Section */}
            <section className="about">
                <h2>About Us</h2>
                <p>
                    DropFiling is a leading platform designed to help you store, organize, and access your important files effortlessly.
                    Whether you're managing personal or business documents, we offer a comprehensive suite of tools that simplifies your workflow.
                </p>
            </section>

            {/* Features Section */}
            <section className="features">
                <h2>Features</h2>
                <ul>
                    <li>Easy file uploads and organization</li>
                    <li>Secure cloud storage for all your important documents</li>
                    <li>Instant access from any device</li>
                    <li>Advanced search and filtering options</li>
                    <li>Customizable folders and tagging</li>
                </ul>
            </section>

            {/* Call-to-Action Section */}
            <section className="cta">
                <h2>Ready to Get Organized?</h2>
                <p>Start organizing your files today with our user-friendly platform. Click below to create an account and get started.</p>
                <button className="cta-button">Sign Up Now</button>
            </section>
            
            {securityLog}
        </div>);
    }

}

export default Home;