import './footer.css';


const Footer = () => {

    return (
        <footer>
            {/* <div>&copy; 2024 Martingale Stochastic Process LLC</div>
            <div>All rights reserved.</div>
            <div>Quotes provided by &nbsp;
                <a href="https://polygon.io" target="_blank">polygon.io</a>.
            </div>
            <div>All market data is delayed by 15 minutes or more.</div>
            <div><a href="/terms-of-use">Terms of Use</a></div> */}
        </footer>
    );
}

export default Footer;