import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { LOGIN_USER } from '../../Utils/mutations';
import Auth from '../../Utils/auth';

const LoginForm = ({ loggedin }) => {
    const [formState, setFormState] = useState({ email: '', password: '' });
    const [login, { error, data }] = useMutation(LOGIN_USER);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormState({
            ...formState,
            [name]: value,
        });
    };
    const handleFormSubmit = async (event) => {
        event.preventDefault();

        try {
            const { data } = await login({
                variables: { ...formState }
            });

            Auth.login(data.login.token);
        } catch (e) {
            
        }

        setFormState({
            email: '',
            password: ''
        });
    }
    return (
        <div className="login">
            <h2>Login Form</h2>
            <form onSubmit={handleFormSubmit}>
                <label htmlFor="email">Email:</label>
                <input
                    name="email"
                    type="email"
                    value={formState.email}
                    onChange={handleChange}
                />
                <label htmlFor="password">Password:</label>
                <input
                    name="password"
                    type="password"
                    value={formState.password}
                    onChange={handleChange}
                />
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default LoginForm;