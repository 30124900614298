import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// Components
import NavBar from './Components/NavBar';
import Header from './Components/Header';

// Pages
import Home from './Pages/Home/index';

// Utils
import Auth from './Utils/auth';

var uriString = 'http://localhost:4000/graphql';

if (process.env.NODE_ENV === 'production') {
  uriString = '/graphql';
}
const httpLink = createHttpLink({
  uri: uriString
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers
    }
  }
});
const client = new ApolloClient({
  connectToDevTools: true,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache().restore({})
});


function App() {
  const loggedin = Auth.loggedIn();

  var email;
  if (loggedin) {
    email = Auth.getProfile().data.email;
  } else {
    console.log('Not logged in');
    email = '';
  }

  return (
    <ApolloProvider client={client}>
      <div className="all">
        <Header />
        <Router>
          <Routes>
            <Route exact path="/" element={<Home loggedin={loggedin} email={email} />} />
          </Routes>
        </Router>
        <NavBar loggedin={loggedin} />
      </div>
    </ApolloProvider>

  );
}

export default App;
