import { gql } from '@apollo/client';

export const LOG_IP = gql`
    mutation Mutation($email: String, $ip: String) {
        logIp(email: $email, ip: $ip) {
            email
            ip
        }
    }
`;

export const EXTRACT_TEXT_FROM_PDF = gql`
    mutation extractTextFromPdf($fileData: String!, $email: String!) {
        extractTextFromPdf(fileData: $fileData, email: $email) {
            text
        }
    }
`;

export const ADD_USER = gql`
    mutation addUser($email: String!, $password: String!) {
        addUser(email: $email, password: $password) {
            token
            user {
                _id
                email
            }
        }
    }
`;

export const LOGIN_USER = gql`
    mutation login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            token
            user {
                _id
                email
            }
        }
    }
`;
