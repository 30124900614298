import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDropzone } from 'react-dropzone';
import { EXTRACT_TEXT_FROM_PDF } from '../../Utils/mutations'; // Your GraphQL mutation for extracting text

const DropzoneComponent = ({ email }) => {
    const [fileName, setFileName] = useState('');
    const [fileData, setFileData] = useState('');
    const [jsonResponse, setJsonResponse] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);

    // Use the mutation to extract text from PDF
    const [extractTextFromPdf] = useMutation(EXTRACT_TEXT_FROM_PDF);

    const onDrop = (acceptedFiles) => {
        acceptedFiles.forEach(file => {
            if (file && file.type === 'application/pdf') {
                setFileName(file.name);
                processFile(file);
            } else {
                alert("Please upload a PDF file");
            }
        });
    };

    const processFile = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(',')[1]; // Extract Base64 part
            setFileData(base64String);
        };
        reader.readAsDataURL(file); // Read file as Base64
    };

    const handleUpload = async () => {
        try {
            // Show progress
            setUploadProgress(50); // Update progress to 50% for visual feedback

            // Make the GraphQL request to extract text from the PDF and convert to JSON
            const { data } = await extractTextFromPdf({
                variables: {
                    fileData: fileData,  // Base64-encoded PDF data
                    email: email         // User email (optional)
                },
            });

            setJsonResponse(data.extractTextFromPdf.text); // Extracted JSON from response
            setUploadProgress(100); // Update progress to 100% after success
            alert('File uploaded and processed successfully!');

        } catch (e) {
            alert('File upload failed!');
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        // accept: ['.pdf', 'application/pdf'],
    });

    return (
        <div>
            <div className="filename-container">
                {fileName && (
                    <div>
                        <h3>File: {fileName}</h3>
                        <progress value={uploadProgress} max="100">{uploadProgress}%</progress>
                        <button onClick={handleUpload}>Upload</button>
                    </div>
                )}
            </div>

            <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop the PDF here...</p>
                ) : (
                    <p>Drag & drop a PDF here, or click to select one</p>
                )}
            </div>




            {jsonResponse && (
                <div className="dropzone-response">
                    <h4>Extracted JSON:</h4>
                    <div>{jsonResponse}</div> {/* Display the extracted JSON */}
                </div>
            )}


        </div>
    );
};

export default DropzoneComponent;
