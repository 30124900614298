import React, { useState } from 'react';
import './navbar.css';

import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import Auth from '../../Utils/auth';

function NavBar({ loggedin }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formType, setFormType] = useState(''); // 'login' or 'signup'

    const openModal = (type) => {
        setFormType(type);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setFormType('');
    };

    const logout = (e) => {
        e.preventDefault();
        Auth.logout();
    }

    const defaultNav = <nav>
        <button className="nav-button" onClick={logout}>Logout</button>
    </nav>;

    if (loggedin) {
        // console.log(window.location.pathname)
        // switch (window.location.pathname) {
        //     case '/': return (<>{homeNav}</>);
        //     case '/account': return (<>{accountNav}</>);
        //     default: return (<>{defaultNav}</>);
        // }
        return defaultNav;
    } else {
        return (
            <nav>
                <button className="nav-button" onClick={() => openModal('login')}>Sign In</button>
                <button className="nav-button" onClick={() => openModal('signup')}>Sign Up</button>

                {/* Modal logic */}
                {isModalOpen && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={closeModal}>
                                &times;
                            </span>
                            {formType === 'login' ? <LoginForm loggedin={loggedin} /> : <SignUpForm loggedin={loggedin} />}
                        </div>
                    </div>
                )}
            </nav>
        );
    }

}

export default NavBar;